import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

var max_width = "78";

export const ArtistsLayout = styled.div`
  display: flex;
  flex-direction: row;

  /* background: yellow; */
  margin: auto;
  max-width: ${max_width}%;
  margin-bottom: 12rem;
  margin-top: 8rem;

  @media screen and (max-width: 1030px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 90%;
    min-width: 300px;
  }
`;
//FLEX LEFT
export const ArtistImg = styled.img`
  height: 300px;
  width: 300px;
  /* box-shadow: 8px 8px #fdc500; */
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1030px) {
    margin: auto;
    margin-top: 2rem;
  }
`;
//FLEX RIGHT
export const ArtistName = styled.h2`
  margin-bottom: 0;
`;
export const ArtistInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  padding-left: 5rem;
  padding-right: 25px;
  width: 100%;
  @media screen and (max-width: 1030px) {
    outline: white 0.5px solid;
    margin-top: 3rem;

    padding: 3%;
    width: 80%;
    text-align: center;
  }
`;
export const ArtistBio = styled.p`
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: left;
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 0.5rem;
  justify-content: start;
  align-items: left;
  font-size: 2rem;
  min-height: 2rem;
  @media screen and (max-width: 1030px) {
    align-items: center;
    text-align: center;
    justify-content: space-around;
  }
`;
export const SocialIconLink = styled.a`
  margin-left: 20px;
  min-height: 100%;
  font-weight: 400;
  stroke: #fff;
  color: #fff;
  stroke-width: "1";
  cursor: pointer;
  @media screen and (max-width: 1030px) {
    margin-left: 0px;
  }
`;

export const ArtistButton = styled(NavHashLink)`
  background: ${(props) => props.bgColor};
  white-space: normal;
  padding: 12px 12px;
  color: #fff;
  font-size: 18px;
  outline-style: solid;
  outline-width: 2px;
  outline-color: ${(props) => props.outline};
  max-width: ${(props) => props.maxWidth};
  border: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  margin-top: 2rem;
  &:hover {
    transition: all 0.3s ease-out;
    background: #ffffff30;
    outline-color: #fff;
  }
  @media screen and (max-width: 1030px) {
    min-width: 80%;
    float: center;
    margin: 1rem auto;
  }
`;
