import React from "react";
import { Link } from "react-router-dom";
import {
  AHere,
  GuideContainer,
  CenteredItems,
  HeadingContainer,
  Instructions,
  InstructionItem,
  GuideImg,
  Break,
} from "./guide.styles";
import {
  onboarding1,
  onboarding2,
  onboarding3,
  onboarding4,
  onboarding5,
  onboarding6,
  onboarding7,
  onboarding8,
  onboarding9,
  onboarding10,
} from "./guide.data.index";

import { A } from "../styles";
import { ScrollToTop } from "../components/ScrollToTop";
const GuidePage = () => {
  return (
    <>
      <GuideContainer>
        <ScrollToTop />
        <h1>Guide</h1>

        <HeadingContainer>
          <body>
            Participating in our NFT auction is easy! You’ll need a
            cryptocurrency wallet with Solana (SOL) to both bid and receive
            Welcome to Chinatown’s 🌟first ever🌟 participation token.
            {"\n\n"}🛑 If you need to set up a wallet, follow our steps below.
            {"\n\n"}
            ✅ If you already have a wallet, what are you waiting for?! {
              "\n"
            }{" "}
            Head over to the{" "}
            {/* <Link to="/auctions" target="_blank" rel="noopener noreferrer"> */}
            <u>Auctions</u>
            {/* </Link> */} page and start bidding.
          </body>
          <Break />
          <Break />
          <Break />
          <Break />
          <A
            href="https://twitter.com/jomaoppa/status/1484587074070134790?s=20&t=nrrwqsRhaqPBcUOWUlhj3A"
            target="_blank"
          >
            <h3>TL;DR</h3>
          </A>
        </HeadingContainer>

        <CenteredItems id="wallet-setup">
          <h2>A. Setting up your wallet</h2>
          <Instructions>
            <InstructionItem>
              We recommend using Phantom as your crypto wallet. To install,
              visit{" "}
              <A href="https://phantom.app/download" target="_blank">
                Phantom
              </A>{" "}
              on your desktop browser and select your browser type. {"\n \n"}🗣
              Phantom launched on an iPhone app on January 31st 2022. The
              instructions are for desktop installation, but if you prefer to
              use their{" "}
              <A
                href="https://apps.apple.com/us/app/phantom-solana-wallet/1598432977"
                target="_blank"
              >
                mobile app
              </A>{" "}
              (iOS only at the moment), it's a similar process.
            </InstructionItem>
            <InstructionItem>
              Phantom will open in a new tab once the install is done.{" "}
              <strong>Select Create New Wallet.</strong>
              <GuideImg src={onboarding1} alt="phantom"></GuideImg>
            </InstructionItem>
            <InstructionItem>
              <u>THIS IS IMPORTANT:</u> You’ll be shown your "Secret Recovery
              Phase".{"\n"}
              <strong>
                Never share this phrase with anyone and do not lose it.
              </strong>{" "}
              This is the only way for you to recover your wallet. No one will
              or should ever ask you for your seed phrase, including Welcome to
              Chinatown. Protect your secret recovery phrase at all costs! Click
              on <strong>OK, I saved it somewhere</strong> only after you’ve
              stored your secret recover phrase in a secure location.
              <GuideImg src={onboarding2} alt="phantom"></GuideImg>
            </InstructionItem>
            <InstructionItem>
              Create a password & agree to the terms of service (after you’ve
              reviewed it, of course).
            </InstructionItem>
          </Instructions>
          <h2 id="wallet-funding">B. Funding the wallet</h2>
          <Break />
          Congrats, you have a wallet 🙌🏽  -- now it’s time to load some SOL in
          it. Depending on your location, follow the steps below.{"\n\n"}
          <b>
            <u>Non-New York State residents</u>
          </b>
          <Break />
          <div>
            To purchase SOL and transfer it into your newly created Phantom
            wallet, we recommend using <b>MoonPay</b>. A separate account is
            needed with MoonPay to make the transfer happen.
          </div>
          <Instructions>
            <InstructionItem>
              Open Phantom, and click on Deposit.{" "}
              <GuideImg src={onboarding3} alt="phantom deposit"></GuideImg>
            </InstructionItem>

            <InstructionItem>
              Then hit <b>Buy with MoonPay</b>.{" "}
              <GuideImg src={onboarding4} alt="buying with moonpay"></GuideImg>
            </InstructionItem>
            <InstructionItem>
              A new tab will open with Moonpay. Enter the amount of SOL you’d
              like to purchase. The minimum amount is 0.33 SOL.
              <GuideImg src={onboarding5} alt="input SOL"></GuideImg>
            </InstructionItem>
            <InstructionItem>
              Enter your email address to create your MoonPay account. And of
              course, also need to review and agree to MoonPay’s Terms of Use
              and Privacy Policy. When creating your MoonPay account, MoonPay
              will request details including your Name, DOB, and billing
              address.
              {"\n\n"}If you already have a MoonPay account, log in with the
              email associated.
            </InstructionItem>
          </Instructions>
          <strong>
            <u>New York State residents</u>
          </strong>
          <Break />
          To purchase SOL and transfer it into your newly created Phantom
          wallet, we recommend using Coinbase. A separate account is needed with
          Coinbase to make the transfer happen.
          <Instructions>
            <InstructionItem>
              Set up your account on{" "}
              <A href="https://www.coinbase.com/" target="_blank">
                Coinbase
              </A>{" "}
              and add a payment method. A payment method is needed to purchase
              SOL.
            </InstructionItem>
            <InstructionItem>
              Go to{" "}
              <A href="https://www.coinbase.com/price/solana" target="_blank">
                Solana’s page in Coinbase
              </A>{" "}
              and click on <b>Buy / Sell</b> in the top right header.
              <GuideImg
                src={onboarding6}
                alt="buying SOL on coinbase"
              ></GuideImg>
            </InstructionItem>
            <InstructionItem>
              Enter the amount you would like to purchase and hit Preview Buy →
              Buy Now. We recommend purchasing at least the equivalent of $5 USD
              to mint our participation token.
              <GuideImg
                src={onboarding7}
                alt="input amount you'd like to buy"
              ></GuideImg>
            </InstructionItem>
            <InstructionItem>
              Alright, you did it! 👏🏽 You purchased some SOL. It’s time to
              transfer it to your wallet to use in the auction. In Coinbase,
              click on <b>Send/Receive</b> in the top right header.
              {"\n\n"}
              🗣 Because of federal finance regulations, Coinbase requires an
              identity verification before you can Send/Receive
              cryptocurrencies.
              <GuideImg
                src={onboarding8}
                alt="transfer SOL to wallet"
              ></GuideImg>
            </InstructionItem>
            <InstructionItem>
              A new box will open and on the Send tab, you’ll tell Coinbase to
              send your SOL to your Phantom wallet. {"\n\n"}
              To get your Phantom wallet address, open Phantom. Your wallet
              address is on the top header. Click on it to copy it.
              <GuideImg src={onboarding9} alt="copy wallet address"></GuideImg>
            </InstructionItem>
            <InstructionItem>
              Go back Coinbase and paste your wallet address in the <b>To</b>{" "}
              box. Enter how much SOL you would like to transfer to your Phantom
              wallet. Make sure the last box says <b>Pay with: Solana </b>and
              hit
              <b> Continue → Send Now.</b>
              <GuideImg
                src={onboarding10}
                alt="sending SOL to wallet"
              ></GuideImg>
            </InstructionItem>
            <InstructionItem>
              Refresh your Phantom wallet and you’ll see your SOL in it ready
              for bidding 🥳
            </InstructionItem>
          </Instructions>
          <h2 id="bid-placing">C. Placing a bid in the auction</h2>
          <Break />
          You’re all set to participate in an auction 🙌🏽  {"\n\n"}
          It’s quite simple: the NFT goes to the highest bidder. You can make as
          many bids as you like while the auction is live. The minimum amount is
          0.1 SOL higher than the current bid. How to place a bid:
          <Instructions>
            <InstructionItem>
              Go to our{" "}
              <Link to="/auctions" target="_blank" rel="noopener noreferrer">
                Auctions
              </Link>{" "}
              page. Browse for the NFT you want to bid on.
            </InstructionItem>
            <InstructionItem>Connect your wallet.</InstructionItem>
            <InstructionItem>Place your bid</InstructionItem>
            <InstructionItem>
              Set a calendar reminder to come back on February 9th at 12:00 PM
              EST to see if you’ve won {"\n\n"}
              ⏰ Want a reminder from Welcome to Chinatown? Sign up for our
              newsletter at{" "}
              <A href="https://www.welcometochinatown.com" target="_blank">
                welcometochinatown.com
              </A>{" "}
              to be notified when the auction closes.{" "}
            </InstructionItem>
          </Instructions>
          <h2 id="end-of-auction">D. When the auction ends</h2>
          <Instructions>
            <InstructionItem>Go back to the auction page.</InstructionItem>
            <InstructionItem>
              If you’ve won, congrats! Claim your winnings by hitting the{" "}
              <b>Claim NFT button.</b> {"\n\n"}
              <u>Did you win the NFT with an Easter Egg prize? 🤯</u>
              {"\n\n"} You will see a form to input your email so that we can
              email your prize.
            </InstructionItem>
            <InstructionItem>
              If you didn’t win, you can reclaim your bid on this page. You will
              also have the option to donate your bid to Welcome to Chinatown,
              too!
              {"\n\n"}* If you don’t claim your winnings within 48 hours,
              Welcome to Chinatown reserves the right to contact the next
              winner.
            </InstructionItem>
          </Instructions>
          <h2 id="participation-nfts">Participation NFTs</h2>
          <Break />
          <div>
            Welcome to Chinatown wants to make NFTs accessible for our
            community. That’s why you can receive a <b>free</b> participation
            NFT designed by{" "}
            <A
              href="https://www.instagram.com/harukohayakawa/?hl=en"
              target="_blank"
            >
              @harukohayakawa
            </A>{" "}
            just for setting up your wallet and{" "}
            <mark>
              providing your email address{" "}
              <AHere href="http://eepurl.com/hTH3vr" target="_blank">
                here.
              </AHere>
            </mark>{" "}
            When the auctions end, you will be emailed a unique link that will
            let you claim this NFT! {"\n\n"}
            All holders of participation tokens will gain access to our WtC
            community by unlocking early access to upcoming events, invites to
            impact study listening sessions, and participation in surveys to
            shape our initiatives.
            {"\n\n"}
            🗣 Participation token NFT recipients are responsible for the storage
            fees (.002 SOL / less than $1) when you claim and mint it. We wish
            we could cover the costs but...we’re a non-profit.
          </div>
        </CenteredItems>
      </GuideContainer>
    </>
  );
};

export default GuidePage;
