import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { Container } from "../../styles";
import { ReactComponent as LogoSVG } from "../../assets/logo.svg";
import { NavHashLink } from "react-router-hash-link";

export const Break = styled.br`
  @media only screen and (min-width: 460px) {
    display: none;
  }
`;
export const Nav = styled.nav`
  height: 100px;
  display: flex;
  padding-left: 2%;
  padding-right: 2%;
  align-items: center;
  justify-content: space-around;

  top: 0;
  z-index: 999;
  width: 100%;
`;
export const IconAndText = styled.div``;
export const Sideways = styled.div`
  transform-origin: left top 0;
  transform: rotate(90deg) translateY(-100%);
  padding-bottom: 0.8rem;
  padding-left: 1.3rem;
  font-size: 1.5rem;
  color: blue;
  float: left;
  position: absolute;
  @media only screen and (max-width: 750px) {
    display: none;
  }
`;

export const NavbarContainer = styled.div`
  margin-top: 0px;
  display: flex;
  margin-left: auto;
  flex-grow: 1;
  justify-content: space-between;
  ${Container}
`;

export const NavLogo = styled(Link)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  padding-left: 1%;
  @media only screen and (max-width: 700px) {
    font-size: 1.5rem;
  }
`;

export const NavIcon = styled(LogoSVG)`
  height: 4rem;
  z-index: 1000;
  margin-right: 1rem;
  @media screen and (max-width: 450px) {
    height: 3.5rem;
  }
`;

export const MobileIcon = styled.div`
  display: none;
  @media screen and (max-width: 1120px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  min-width: 35rem;
  justify-content: space-between;
  z-index: 999;
  @media screen and (max-width: 1120px) {
    margin-top: 1rem;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
    top: 80px;
    align-items: space-around;
    left: ${({ click }) => (click ? 0 : "-150%")};
    opacity: 1;
    transition: all 0.5s ease;
    text-align: left;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #000;
    border-bottom: white solid 0.5px;
    border-top: white solid 0.5px;
  }
`;

export const NavItem = styled.li`
  height: 50px;
  border-bottom: 2px solid transparent;
  &:hover {
    text-underline-offset: 1rem;
    text-decoration: underline;
  }
  @media screen and (max-width: 1030px) {
    width: 100vw;
    padding: 0;
    text-align: left;
    background-color: #000;
    &:hover {
      text-decoration: none;
    }
  }
`;

export const NavLinks = styled(NavLink)`
  color: white;
  &.active {
    color: #ffe000;
  }
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 15px 15px;
  height: 100%;
  font-size: 18px;

  @media screen and (max-width: 1030px) {
    padding: 1rem;
    width: 100%;
    display: table;
  }
`;

export const NaviHashLink = styled(NavHashLink)`
  text-align: left;
  @media screen and (max-width: 1120px) {
    padding: 1rem;
    padding-left: 3rem;
    display: table;
    text-align: left;
    width: 100vw;
  }
`;

export const GuideBurger = styled.div`
  display: none;
  color: white;
  text-align: left;
  @media screen and (max-width: 1120px) {
    display: ${({ click }) => (click ? "none" : "block")};
    text-align: left;
    background-color: #000;
  }
`;
