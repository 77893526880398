export const MenuItems = [
  {
    title: "a. wallet setup",
    path: "/guide#wallet-setup",
  },
  {
    title: "b. wallet funding",
    path: "/guide#wallet-funding",
  },
  {
    title: "c. placing a bid",
    path: "/guide#bid-placing",
  },
  {
    title: "d. end of auction",
    path: "/guide#end-of-auction",
  },
  {
    title: "participation NFTs",
    path: "/guide#participation-nfts",
  },
];
