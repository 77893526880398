import long1 from "../assets/long1.jpg";
import long2 from "../assets/long2.png";
import long3 from "../assets/long3.jpg";

export const wtclogo = require("../assets/wtc-coin.gif");
export const participationTok = require("../assets/participation_token.gif");
export const partnersLogosImg = require("../assets/partners.png");
export const visionImg = require("../assets/WtC-train-final.png");
export const longImg = require("../assets/longetivity_fund.png");

export const longetivityArray = [
  {
    img: long1,
    alt: "Longetivity Recipient 1",
    name: "Ben's Meat Market",
    business: "December 2020 Grant Recipient ",
    quote:
      '"Our shop is located in the heart of Manhattan Chinatown and we sell fresh meat, cooked food, groceries, baked goods, and more. Even under the pandemic, we decided not to increase prices and sell at a lower price to benefit our customers. All our groceries are sold at a lower price, to ensure our community can enjoy delicious food at a reasonable price. An ancient Chinese motto goes, “Many a little makes a mickle, ****Water dripping into a river”. We’d rather work more to make our customers happier. What we earn is not important, and to be happy is the most important. We truly appreciate the help of Welcome To Chinatown and the grant which will help ease the economic burden of our business."',
    link: "https://www.welcometochinatown.com/news/business-spotlight-ben-meat-market",
  },
  {
    img: long2,
    alt: "Longetivity Recipient 2",
    name: "K&E Jewelry",
    business: "February 2021 Grant Recipient ",
    quote:
      "\"My commercial store tenant K & E Jewerly received funding from your organization. The money that the organization gave him has helped him a lot and managed to keep his business operating.  A little background information about his and I relationship. I met him about 16 years ago on the streets of Chinatown when I was trying to find a new renter for my commercial space. He was in another space down the block that was closing(for ten years). Since he has become my tenant, we have become family friends and I watched his daughters grow up in Chinatown.  I would consider him more of a friend than a tenant. When Covid hit, I was extremely worried about his business and his family. I did my best to give all the concessions I can. We have asked for help from everything from the city/state/local leaders. We haven't received any help from any of them. However with your organization's help, it gave us hope and light that perhaps we can continue in Chinatown.  We are forever grateful for your help.\"",
    link: "https://www.welcometochinatown.com/news/business-spotlight-k-e-fine-jewelry",
  },
  {
    img: long3,
    alt: "Longetivity Recipient 3",
    name: "Golden Diner",
    business: "January 2022 Grant Recipient",
    quote:
      '"I’m grateful to be so closely connected with Welcome to Chinatown who work tirelessly to uplift our community. Not only do they directly support small businesses like mine, they exemplify the new wave of community builders and leaders. Welcome to Chinatown is preserving Chinatown’s culture steeped in history, while shaping its future in a fresh and conscientious way."',
    link: "https://www.instagram.com/p/CYcUeuZFGq8/",
  },
];
