import styled from "styled-components";
import { NavHashLink } from "react-router-hash-link";

export const MenuLink = styled(NavHashLink)`
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
  font-size: 0.9rem;
  line-height: 1rem;
  padding-right: 0;
  margin-right: 0;
`;
export const DropDownMenu = styled.ul`
  width: 200px;
  position: relative;
  top: 0;
  list-style: none;
  text-align: start;
  z-index: 1000;
  border-left: white solid 0.1px;
  padding-right: 0;
  margin-right: 0;
  max-width: 11rem;
`;
export const MenuItem = styled.a`
  padding: 0rem;
`;

export const SpacerDiv = styled.div`
  height: 100px;
  margin-top: -100px;
  position: relative;
  z-index: -1;
`;

export const ContainerDiv = styled.div`
  position: absolute;
`;
