import React, { useEffect, useState } from "react";

import { Auction } from "@metaplex-foundation/mpl-auction";
import { Connection, LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { NFTATag, NFTNum, NFTPrice, NFTImg } from "../pages/auction.styles";

const connection = new Connection("https://ssc-dao.genesysgo.net/");

const NFT = ({ nft, index, nftnum }) => {
  const [price, setPrice] = useState(null);

  useEffect(() => {
    async function getPrice() {
      const auctionPDA = new PublicKey(nft.nftlink.split("#/auction/")[1]);
      const auction = await Auction.load(connection, auctionPDA);
      let lowPrice = 1;
      if (auction.data.bidState?.bids.length) {
        const [bid] = auction.data.bidState.bids.slice(-1);
        lowPrice = (bid.amount.toNumber() / LAMPORTS_PER_SOL).toFixed(2);
      }

      setPrice(lowPrice);
    }
    if (nft && nft.nftlink) {
      getPrice();
      let interval = setInterval(() => getPrice(), 1000 * 30);
      //destroy interval on unmount
      return () => clearInterval(interval);
    }
  }, []);

  return (
    <NFTATag href={nft.nftlink} target="_blank">
      <NFTNum>{nftnum}</NFTNum>
      {price && <NFTPrice> {price} SOL&nbsp;</NFTPrice>}

      <NFTImg src={nft.image} alt="holder" />
    </NFTATag>
  );
};

export default NFT;
