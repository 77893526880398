import React, { useState } from "react";

import { MenuItems } from "./menu.items";
import {
  MenuLink,
  DropDownMenu,
  SpacerDiv,
  ContainerDiv,
  MenuItem,
} from "./styles";

export const Dropdown = (props) => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  return (
    <>
      <ContainerDiv onMouseLeave={props.onMouseLeave}>
        <SpacerDiv></SpacerDiv>
        <DropDownMenu>
          {MenuItems.map((item, index) => {
            return (
              <MenuItem key={index}>
                <MenuLink to={item.path}>{item.title}</MenuLink>
              </MenuItem>
            );
          })}
        </DropDownMenu>
      </ContainerDiv>
    </>
  );
};
