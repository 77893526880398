import styled from "styled-components";
import { Container } from "../styles";
import { NavHashLink } from "react-router-hash-link";

var max_width = "78";

export const BreakPoint = styled.br`
  @media screen and (min-width: 1030px) {
    display: none;
  }
`;

export const OutlineBox = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: 0.1rem solid #ffe000;
  width: 45%;
  min-height: 18rem;
  text-align: center;
  margin-bottom: 2rem;

  @media screen and (max-width: 1030px) {
    width: 80%;
  }
`;
//Overall page div

export const AboutContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* outline: 1px solid yellow; */
`;
export const HeadingAndImg = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* outline: white solid 1px; */
  align-self: flex-start;
  width: 100%;
`;

export const CenteredItems = styled.div`
  /* outline: 1px solid yellow; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 5rem;
  max-width: ${max_width}%;
  min-height: ${(props) => props.minheight}rem;
  text-align: left;
  background-color: ${(props) => (props.isWhite ? "white" : "black")};
  color: ${(props) => (props.isWhite ? "black" : "white")};
  outline: ${(props) => (props.isOutline ? "#FFE000 solid 1px" : "none")};
  padding-left: ${(props) => props.padding}rem;
  padding-right: ${(props) => props.padding}rem;
  padding-top: ${(props) => props.padding}rem;
  padding-bottom: ${(props) => props.padding}rem;
  border-radius: 25px;
  @media screen and (max-width: 1030px) {
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    max-width: 85%;
  }
  @media screen and (max-width: 800px) {
    padding-left: ${(props) => props.screenpadding}rem;
    padding-right: ${(props) => props.screenpadding}rem;
    padding-top: ${(props) => props.screenpadding}rem;
    padding-bottom: ${(props) => props.screenpadding}rem;
  }
`;
export const PartnersHeading = styled.h2`
  color: black;
  background-color: white;
`;

export const RoadMapHeading = styled.h3`
  /* color: black;
  background-color: white; */
  text-decoration: underline;
`;
export const ImageLeft = styled.div`
  margin-top: 4rem;
  max-width: ${max_width}%;
  display: flex;
  flex-direction: row;
  align-content: space-between;
  align-items: center;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    /* outline: 1px solid white; */
    max-width: 90%;
    text-align: left;
  }
`;

export const GridWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 3rem;
`;

export const GridCell = styled(NavHashLink)`
  margin: 0 2rem;
  line-height: 2;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const GridImg = styled.img`
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  justify-self: center;
`;

export const CellInfo = styled.div`
  /* outline: 1px solid yellow; */
  display: flex;
  flex-direction: column;
  min-height: 100px;
  justify-content: flex-start;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const CellTitle = styled.div`
  /* outline: 1px solid yellow; */
  font-size: 1rem;
  font-weight: bold;
`;

export const CellArtist = styled.p`
  font-size: 1rem;
  text-decoration: none;
  /* outline: 1px solid yellow; */
`;

export const HorizontalLine = styled.div`
  margin-top: 3rem;
  margin-bottom: 2rem;
  width: 80%;
  border-bottom: 0.1rem solid white;
`;

// PARTICIPATION SECTION
export const TokenWrapper = styled.div`
  cursor: pointer;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-right: 3rem;
  text-align: center;
  @media screen and (max-width: 1030px) {
    margin: 0;
    margin-bottom: 2rem;
  }
`;
export const TokenImg = styled.img`
  max-height: 15rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0rem;
`;

export const TokenText = styled.div`
  align-content: center;
  @media screen and (max-width: 1030px) {
    text-align: left;
  }
`;

// PARTNERS SECTION

export const PartnersLogos = styled.img`
  margin-top: 3rem;
  max-width: 100%;
`;
export const PartnersText = styled.div`
  max-width: ${max_width}rem;
`;

export const VisionText = styled.p`
  /* max-width: ${max_width}rem; */
  margin-top: 0.5rem;
  padding-top: 2rem;
`;

export const Vision = styled.div`
  margin-right: 4rem;
  margin-left: 2rem;
  max-width: 12rem;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const VisionCaption = styled.div``;
export const VisionImg = styled.img`
  max-height: 12rem;
  max-width: 12rem;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 800px) {
    height: 8rem;
    width: 8rem;
  }
`;

export const VisionList = styled.ul`
  margin-top: 2rem;
  text-align: left;
  padding-left: 4%;
  width: 96%;
  @media screen and (max-width: 1030px) {
    padding-left: 2rem;
  }
  @media screen and (max-width: 800px) {
    margin-top: 1rem;
    text-align: left;
    padding-left: 2%;
    width: 100%;
  }
`;
export const WtCAbout = styled.div`
  margin-top: 0.5rem;
  display: block;
`;

export const LogoImg = styled.img`
  max-width: 10rem;
  margin-right: 4rem;
  margin-left: 2rem;
  margin-bottom: 4rem;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 800px) {
    max-height: 8rem;
    margin-left: 1rem;
    margin-right: 2rem;
  }
`;

export const LongetivityImg = styled.img`
  max-height: 12rem;
  margin-right: 4rem;
  margin-left: 2rem;
  margin-bottom: 4rem;
  @media screen and (max-width: 800px) {
    max-height: 8rem;
    margin-left: 1rem;
    margin-right: 2rem;
  }
`;

export const LongetivityText = styled.div`
  @media screen and (max-width: 1030px) {
    margin-left: 0;
  }
`;

export const RecipientLayout = styled.div`
  display: flex;
  flex-direction: row;

  /* background: yellow; */
  margin: auto;
  max-width: ${max_width}%;
  margin-bottom: 8rem;
  margin-top: 8rem;
  @media screen and (max-width: 1030px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 85%;
    min-width: 300px;
  }
`;
//FLEX LEFT
export const RecipientImg = styled.img`
  height: 300px;
  min-width: 300px;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  @media screen and (max-width: 1030px) {
    margin: auto;
    margin-top: 3rem;
    max-width: 80%;
    max-height: 80%;
    margin-bottom: 2rem;
  }
  @media screen and (max-width: 800px) {
    margin-top: 0;
  }
`;
//FLEX RIGHT
export const RecipientInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  padding-left: 3rem;
  flex-grow: 2;
  font-size: 0.9rem;
  @media screen and (max-width: 1030px) {
    padding-top: 1rem;

    padding-left: 0;
    padding-bottom: 1rem;
  }
`;
export const RecipientBio = styled.p`
  margin-top: 20px;
  flex-grow: 2;
`;
