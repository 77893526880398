import React, { useEffect, useState } from "react";
import EasterEggImg1 from "../assets/easter_egg_guide-01.png";
import EasterEggImg2 from "../assets/easter_egg_guide-02.png";
import { OutlineBox } from "../pages/about.styles";
import {
  AuctionContainer,
  AuctionLayout,
  Instructions,
  EEHeader,
  EEWrapper,
  EasterEggGuide,
  EasterEggImage,
  NFTImg,
  EnlargeTray,
  NFTMask,
  NFTNum,
  NFTPrice,
  TrayImg,
  TrayGrid,
  TrayInfo,
  TrayName,
  TrayDesigner,
  TrayDescription,
  TextOverlay,
  ClickHereText,
  NFTATag,
  WhiteOutlineBox,
} from "./auction.styles";
import NFT from "../components/NFT";
import {
  BlinkMe,
  FullScreen,
  FullScreenImage,
  CloseIcon,
  Spacer,
} from "../styles";
import { artistsArray } from "./artists.data.index";

const AuctionsPage = () => {
  const [click, setImage] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");
  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setImage(true);
  };
  const shuffle = (array) => [...array].sort(() => Math.random() - 0.5);

  const newArtistsList = shuffle(artistsArray);

  return (
    <AuctionContainer>
      <h1>Live Auction</h1>

      <OutlineBox>
        {/* <BlinkMe>AUCTION NOW LIVE</BlinkMe> */}

        <h2>⚡⚡⚡</h2>
        <h3>2022 Lunar New Year</h3>
        <h3>Feb 2 12PM – Feb 9 12PM (ET)</h3>
        <Spacer size={2} />
        <Instructions>
          Click on the tray images to be redirected to their auction pages.{" "}
          <br /> The pages may take 1 -2 minutes to load.
        </Instructions>
      </OutlineBox>
      <Spacer size={8} />
      <EasterEggGuide>
        <EEHeader>Easter Egg Prizes</EEHeader>
        <div>
          Remember that feeling of opening a red envelope in secret- the
          exhilarating rush to see what's inside? Hidden in 20 NFTs are easter
          eggs that correlate to a prize from our partners below. The prizes
          range for experiences, exclusive access + merchandise, and will be
          given to winners holding the NFTs with the easter eggs at the close of
          our auction.
        </div>
        <EEWrapper>
          <EasterEggImage src={EasterEggImg1} alt="guide to easter eggs" />
          <EasterEggImage src={EasterEggImg2} alt="guide to easter eggs" />
        </EEWrapper>
      </EasterEggGuide>
      <Spacer size={8} id="trays" />
      {newArtistsList.map((artist, index) => {
        return (
          <AuctionLayout>
            <TrayGrid id={artist.trayname}>
              {artist.individuals.map((nft, j) => {
                if (index === 2) {
                  if (j === 4) {
                    return <NFT nft={nft} nftnum={null} index={index} />;
                  }
                  if (j > 4) {
                    return <NFT nft={nft} nftnum={`#${j} `} index={index} />;
                  } else {
                    return (
                      <NFT nft={nft} nftnum={`#${j + 1} `} index={index} />
                    );
                  }
                } else {
                  return <NFT nft={nft} nftnum={`#${j + 1} `} index={index} />;
                }
              })}
            </TrayGrid>

            <TrayInfo>
              <EnlargeTray>
                <FullScreen click={click} onClick={() => setImage(false)}>
                  <FullScreenImage click={click} src={tempimgSrc} />
                  <CloseIcon />
                </FullScreen>
                <TextOverlay onClick={() => getImg(artist.trayimg)}>
                  <TrayImg src={artist.trayimg} alt={artist.alt} />
                  <ClickHereText>click for{"\n"}full tray</ClickHereText>
                </TextOverlay>
              </EnlargeTray>

              <WhiteOutlineBox>
                <TrayName>{artist.trayname}</TrayName>
                <TrayDesigner to={`/artists#${artist.name}`}>
                  {artist.name}
                </TrayDesigner>
                <TrayDescription>{artist.traydesc}</TrayDescription>
              </WhiteOutlineBox>
            </TrayInfo>
          </AuctionLayout>
        );
      })}
    </AuctionContainer>
  );
};

export default AuctionsPage;
