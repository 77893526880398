import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import { GlobalStyle } from "./styles";

import AboutPage from "./pages/about";
import ArtistsPage from "./pages/artists";
import GuidePage from "./pages/guide";
import AuctionsPage from "./pages/auctions";
import GiftPage from "./pages/gift";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

const theme = createTheme({
  typography: {
    fontFamily: "'Source Code Pro', monospace",
  },
});

export default function BasicExample() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter id="Router">
          <GlobalStyle />
          <Navbar />
          <Routes>
            <Route path="/" element={<AboutPage />}></Route>
            <Route path="/about" element={<AboutPage />}></Route>
            <Route path="/guide" element={<GuidePage />}></Route>
            <Route path="/artists" element={<ArtistsPage />}></Route>
            <Route path="/auctions" element={<AuctionsPage />}></Route>
            <Route path="/gift" element={<GiftPage />}></Route>
          </Routes>
        </BrowserRouter>

        <Footer />
      </ThemeProvider>
    </>
  );
}
