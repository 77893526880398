export const onboarding1 = require("../assets/onboarding1.png");
export const onboarding2 = require("../assets/onboarding2.png");
export const onboarding3 = require("../assets/onboarding3.png");
export const onboarding4 = require("../assets/onboarding4.png");
export const onboarding5 = require("../assets/onboarding5.png");
export const onboarding6 = require("../assets/onboarding6.png");
export const onboarding7 = require("../assets/onboarding7.png");
export const onboarding8 = require("../assets/onboarding8.png");
export const onboarding9 = require("../assets/onboarding9.png");
export const onboarding10 = require("../assets/onboarding10.png");
