import styled from "styled-components";

export const ButtonLocation = styled.div`
  position: fixed;
  right: 0px;
  top: 0px;
  padding-right: 2%;
  padding-top: 1%;
  @media screen and (max-width: 400px) {
    display: none;
  }
`;
export const TheButton = styled.a`
  display: ${(props) => (props.isVisible ? "block" : "none")};
  cursor: pointer;
  font-weight: 400;
  color: #fff;
  font-size: 2rem;
`;
