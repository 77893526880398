import React, { useState } from "react";
import { NavHashLink } from "react-router-hash-link";
import {
  BreakPoint,
  OutlineBox,
  AboutContainer,
  IntroText,
  CenteredItems,
  HeadingAndImg,
  ImageLeft,
  GridWrapper,
  GridCell,
  GridImg,
  CellInfo,
  CellTitle,
  CellArtist,
  HorizontalLine,
  TokenWrapper,
  TokenImg,
  TokenText,
  PartnersLogos,
  PartnersHeading,
  PartnersText,
  VisionText,
  LogoImg,
  WtCAbout,
  VisionImg,
  RoadMapHeading,
  LongetivityImg,
  LongetivityText,
  RecipientInfo,
  RecipientLayout,
  RecipientImg,
  RecipientBio,
  VisionList,
  Vision,
  VisionCaption,
} from "./about.styles";

import {
  A,
  FullScreen,
  FullScreenImage,
  CloseIcon,
  Spacer,
  BlinkMe,
} from "../styles";
import { Link } from "react-router-dom";
import {
  longetivityArray,
  wtclogo,
  participationTok,
  partnersLogosImg,
  visionImg,
  longImg,
} from "./about.data.index";

import { artistsArray } from "./artists.data.index";
import { ScrollToTop } from "../components/ScrollToTop";

const AboutPage = () => {
  const [click, setImage] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");
  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setImage(true);
  };
  return (
    <AboutContainer>
      <ScrollToTop />

      <h1>TRAYS OF TOGETHERNESS</h1>
      <OutlineBox>
        {/* <BlinkMe>AUCTION NOW LIVE</BlinkMe>
        <NavHashLink to="/auctions#trays">
          <h3>
            <u>Start Bidding Now{"\n\n"}</u>
          </h3>
        </NavHashLink> */}

        <h2>⚡⚡⚡</h2>

        <h3>2022 Lunar New Year</h3>
        <h3>Feb 2 12PM – Feb 9 12PM (ET)</h3>
      </OutlineBox>
      <CenteredItems>
        <div>
          Blending our values of storytelling, innovation, and bridging cultural
          + generational gaps,{" "}
          <A href="https://www.welcometochinatown.com/" target="_blank">
            Welcome to Chinatown
          </A>{" "}
          is launching its first NFT collection to support its Longevity Fund.{" "}
          <br />6 AAPI designers created interpretations of a “Tray of
          Togetherness” – a platter of treats often enjoyed and gifted during
          Lunar New Year. Each tray consists of 8-9 NFTs.{"\n \n \n"}
          These interpretations celebrate the uniqueness of our individual
          contributions and symbolize the strength of community. Chinatown is
          stronger when we all come together.
        </div>
      </CenteredItems>
      <GridWrapper>
        {artistsArray.map((tray, index) => {
          return (
            <GridCell key={index} to={`/auctions#${tray.trayname}`}>
              <GridImg src={tray.trayimg} alt={tray.alt_tray} />
              <CellInfo>
                <CellTitle>{tray.trayname}</CellTitle>
                <CellArtist>{tray.name}</CellArtist>
              </CellInfo>
            </GridCell>
          );
        })}
      </GridWrapper>
      <HorizontalLine />
      <ImageLeft>
        <TokenWrapper>
          <FullScreen click={click} onClick={() => setImage(false)}>
            <FullScreenImage click={click} src={tempimgSrc} />
            <CloseIcon />
          </FullScreen>
          <TokenImg
            onClick={() => getImg(participationTok)}
            src={participationTok}
            alt="participation-token"
          ></TokenImg>
          Click to enlarge
        </TokenWrapper>
        <TokenText>
          <h3>Free Participation NFTs</h3>
          {"\n\n\n"}
          We're gifting tokens, designed by{" "}
          <A
            href="https://www.instagram.com/harukohayakawa/?hl=en"
            target="_blank"
          >
            Haruko Hayakawa
          </A>
          , to all who participate in our Lunar New Year Fundraiser (no monetary
          bid required)! Owners of this token will join our digital community,
          and unlock access to exclusive events and opportunities to come. Sign
          up for a participation NFT{" "}
          <u>
            <A href="http://eepurl.com/hTH3vr" target="_blank">
              here
            </A>
          </u>{" "}
          and learn more in our{" "}
          <u>
            <Link to="/guide" target="_blank">
              guide
            </Link>
          </u>
          .
        </TokenText>
      </ImageLeft>
      <Spacer size={2} />
      <HorizontalLine />
      <Spacer size={15} />
      <CenteredItems>
        <h2>How to Participate</h2>
        <div>
          53 unique NFTs are available for auction. Winners of our auction and
          holders of our participation token will be the first to join Welcome
          to Chinatown’s digital community and receive exclusive access to
          upcoming IRL events. Check out our{" "}
          <u>
            <Link to="/guide">handy guide</Link>
          </u>{" "}
          to learn how to set up a wallet and place a bid.
        </div>
      </CenteredItems>
      <Spacer size={13} />
      <CenteredItems
        minheight={18}
        isWhite={true}
        padding={3}
        screenpadding={1.5}
      >
        <PartnersHeading>Surprises From Our Partners</PartnersHeading>
        Hidden in 20 NFTs are easter eggs that correlate to a prize from our
        partners below. The prizes range for experiences, exclusive access +
        merchandise, and will be given to winners at the close of our auction.
        It’s like a digital surprise red envelope!
        <PartnersLogos src={partnersLogosImg}></PartnersLogos>
      </CenteredItems>
      <Spacer size={12} />
      <CenteredItems>
        <HeadingAndImg>
          <h4>Vision</h4>
          <Vision>
            <VisionImg src={visionImg} alt="img of winding road"></VisionImg>
            <VisionCaption>By Anna-Marie Cheung and Jasco Chan</VisionCaption>
          </Vision>
        </HeadingAndImg>
        <VisionText>
          It’s mind blowing we’re here...launching an NFT collection. During the
          early days of WtC, our co-founders could barely convince cash-only
          businesses to digitalize, as NYC was days away from lock down.
          Business owners were skeptical – two naive residents speaking in
          broken Cantonese, advising them to use an e-gift card platform. With
          each conversation, we learned why businesses didn’t want to
          digitalize. Since then, we’ve applied these learnings and nuances to
          our core values and operating principles: we never assume we know the
          answer. We may dabble in experiments that don’t succeed, but we’ll
          always walk away with knowledge.
          {"\n\n"}
          This Lunar New Year collection was built from learnings. It is the
          beginning of our long term vision to create a digital community that
          celebrates the sights, sounds, experiences, and memories evoked by
          Chinatown.{" "}
          <b>
            We’re wide-eyed, thinking about novel ways to introduce financial
            tools, give brand ownership to entrepreneurs, and innovate in a way
            that blends the physical and digital world – all while celebrating
            and preserving Chinatown.
          </b>{" "}
          {"\n\n"}All of this is new to us, but we’re excited that you will join
          us in our mission. Together, we will keep Chinatown open for
          generations to come.
        </VisionText>
      </CenteredItems>
      <Spacer size={3}></Spacer>

      <CenteredItems isOutline={true} padding={3}>
        <RoadMapHeading>NFT Roadmap 1.0</RoadMapHeading>
        <Spacer size={2} />
        Our roadmap will change and evolve with our community’s input. For now,
        here’s what we have in store for our NFT holders:
        <Spacer size={2} />
        <b>Tray NFT Holder:</b>
        <VisionList>
          <li>Guaranteed access to our May APAHM NFT launch</li>
          <li>Entry to our “Nights Like This” summer event </li>
          <li>Surprise drops 👀</li>
          <li>
            Invitation to our Longevity Fund Economic, Social, and Health Impact
            Study Listening Session
          </li>
          <li>
            Participation in surveys and focus groups to shape our forthcoming
            initiatives{" "}
          </li>
        </VisionList>
        <Spacer size={2} />
        <b>Participation Token Holder:</b>
        <VisionList>
          <li>
            Early bird access to tickets for our “Nights Like This” summer event
          </li>
          <li>
            Invitation to our Longevity Fund Economic, Social, and Health Impact
            Study Listening Session
          </li>
          <li>
            Participation in surveys and focus groups to shape our forthcoming
            initiatives
          </li>
        </VisionList>
      </CenteredItems>
      <Spacer size={15} />
      <CenteredItems>
        <HeadingAndImg>
          <h2>
            About <br />
            Welcome to Chinatown
          </h2>
          <LogoImg src={wtclogo} alt="wtc-logo" />
        </HeadingAndImg>
        <WtCAbout>
          <b>
            Welcome to Chinatown is a non-profit organization amplifying and
            addressing the critical needs of our community and its
            entrepreneurs.
          </b>{" "}
          Founded in March 2020,{" "}
          <A href="https://www.welcometochinatown.com/" target="_blank">
            Welcome to Chinatown
          </A>
          &nbsp; started when the COVID-19 pandemic combined with increased
          xenophobia, decreased foot traffic, and lack of advocacy resulted in
          tremendous hardship for Manhattan’s Chinatown. {"\n \n \n"}
          Our two co-founders, Victoria (Vic) Lee and Jennifer Tam, two Asian
          American women living in Chinatown, knew that small businesses were
          integral in preserving this culturally significant ethnic enclave for
          future generations. The grassroots initiative has evolved into a
          multimillion dollar non-profit supporting and advocating for hundreds
          of Chinatown’s entrepreneurs. Together, we will keep Chinatown open
          for generations to come.
        </WtCAbout>
      </CenteredItems>
      <Spacer size={15} />
      <CenteredItems>
        <HeadingAndImg>
          <h2>Longevity Fund</h2>
          <LongetivityImg src={longImg} alt="longetivity-fund" />
        </HeadingAndImg>
        <LongetivityText>
          The only small business relief program exclusively for Manhattan’s
          Chinatown. To date, the{" "}
          <A
            href="https://www.welcometochinatown.com/the-longevity-fund-small-business-grants"
            target="_blank"
          >
            Longevity Fund
          </A>{" "}
          has distributed 83 grants totaling over $515,000 to support
          Chinatown’s economic recovery and invest in the future of our vital
          entrepreneurs.{"\n\n"}
          <b>
            Proceeds from our NFT fundraiser will help us expand the Longevity
            Fund by distributing grants to the most at-risk, evolve our
            accelerator programmatic offerings, conduct a much needed impact
            study, and start the development of a small business innovation
            center.
          </b>
        </LongetivityText>
      </CenteredItems>
      {longetivityArray.map((recipient, index) => {
        return (
          <RecipientLayout>
            <RecipientImg src={recipient.img} alt={recipient.alt} />

            <RecipientInfo>
              <h3>
                <u>{recipient.name}</u>
              </h3>
              <h3>{recipient.business}</h3>
              <RecipientBio>{recipient.quote}</RecipientBio>

              <b>
                {"\n\n"}Learn more about their story{" "}
                <A href={recipient.link} target="_blank">
                  here.
                </A>
              </b>
            </RecipientInfo>
          </RecipientLayout>
        );
      })}
    </AboutContainer>
  );
};

export default AboutPage;

//<ImageContainer clickable url={"about"} src={photo} />
/*export const ImageContainer = (props) => {
  width: ${size};
  height: ${size};
  background-image: url(${props.photo});
  background-size: cover;
  const url = props.url ? props.url : "";
  console.log(props);
  const navigate = useNavigate()

  const handleClick = () => {
      if (!props.clickable) return
      navigate(url)
  };
  return <image onClick={handleClick}></image>;
};
*/
/*
export const ContainerImage = styled(SmartPictureS)`
  margin-right: 25%;
`;
*/
