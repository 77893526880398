const tokens = {
  wendy: [
    {
      tokenName: "Lunar Everyday an Object #1",
      auction: "DVFbyttt8PGeNPwq3iBgS3LyY1M7uW2ajdFfzx2hC2YK",
      mintAddress: "3dPQ3D6TVNDJuGiKsWjP1gB7EkErymUVzkwZ7z3RXWNP",
    },
    {
      tokenName: "Lunar Everyday an Object #2",
      auction: "72RSasnv9S5uvNJ8MMNXfUuj7VMUXsgAg7YmRgG3RPwp",
      mintAddress: "D4eHn8c82P5ViSK3X8jthG3NiypqLMm9g92u8Do4nVMa",
    },
    {
      tokenName: "Lunar Everyday an Object #3",
      auction: "FZZTXzEp2oiuY19XyWY4DZgfoAKQGPXscabbNydcQhYq",
      mintAddress: "7AXn8Qi7kHXcCsR6Eh9bDL7LsB3MPmt9cmGZCoFM1eUN",
    },
    {
      tokenName: "Lunar Everyday an Object #4",
      auction: "HYgXpscEXZN85Q1u4pEdm2nJVixLEhpiGQcHGreTBT1A",
      mintAddress: "FyvkatBqtH8wXkx6uE6Ek4BZozSEPJLFrmmehyRDbppS",
    },
    {
      tokenName: "Lunar Everyday an Object #5",
      auction: "8ee99Zc2FkNPT9fXUd7MLc8oFNydzYjsnGG9b9xA5pwy",
      mintAddress: "5Rjc396nxRxJCs4mJL89uvTxhP7v1uf3LJPJX8LPuESx",
    },
    {
      tokenName: "Lunar Everyday an Object #6",
      auction: "9nUAfjLxdc6nQmgg5odaVe2KspS9NAoDP2oV4cPgFhxH",
      mintAddress: "8B6AKh64pgbweu2majb87unHaupSbexEUg4e9Bp3t57A",
    },
    {
      tokenName: "Lunar Everyday an Object #7",
      auction: "8JXvrShp6JRyQxTGE7KiSx3WnrQjvK1Y5c3Yp6wrsvGQ",
      mintAddress: "HBGsx2i8BV4T81hMh6XRxHBhDQZykS49pVLEt7KmAuMr",
    },
    {
      tokenName: "Lunar Everyday an Object #8",
      auction: "4mxDFrykqfN7CcE2XdHv2SusK1p7bLiKCFMJg8YT1SYW",
      mintAddress: "GTpXU1mmL4VaQGyKsWtKn3yt3kphDy7LEDKLtmZU3WmD",
    },
    {
      tokenName: "Lunar Everyday an Object #9",
      mint: "AUm4wjbqSz3w3fe8HNci3Ec5R2PXKQVyg6e2FXfezCyJ",
      auction: "7Jn337ZUFgibeDi1Ak5muUjrKvjNn9C5DnYcW2TvgXxY",
    },
  ],
  spaced: [
    {
      tokenName: "THE SUM OF US #1",
      auction: "7mftjqw3jvpsGrMLVCJjLKnqVspLv68k6wK2tFgSJtw9",
      mintAddress: "31Ne9DrMjtYGMv6zsCi7eTrg6Zku6Sym9rbBxPsrP9Yj",
    },
    {
      tokenName: "THE SUM OF US #2",
      auction: "4A4EV3G5fQGB2av1z9a8jGTm9dwkCaeu2Mh5ygt2YYS8",
      mintAddress: "VCbxBFYTx831QxksC4gXFhbuf13gcALy3hFbXjLCt5y",
    },
    {
      tokenName: "THE SUM OF US #3",
      mint: "EyEgqEYtcJnQZRxDb2B3NR4WEpDxM2tGPPen4FshVPBB",
      auction: "Hb9Z5HUHLfNHc6UrtqkzcGGAYkU6EtLbr5kEs67CDCY1",
    },
    {
      tokenName: "THE SUM OF US #4",
      auction: "Bzhjnhv1UvNnnUDPLbqEJSVacZE6LWA1QRhoHV8dqCJf",
      mintAddress: "C55cYjfynydkLYMbNYTK4apDFEADQEFzLuyCC95Cegcr",
    },
    {
      tokenName: "THE SUM OF US #5",
      auction: "BgQaUDmwGuhyfyHDepbvDmfGB55LNcSz5DKJ4oTy8njs",
      mintAddress: "EGSqRYufdsAmwmzBJzgCiiaADn9EdCmRaJx98jjuNshz",
    },
    {
      tokenName: "THE SUM OF US #6",
      auction: "4ktpSMPKPaziHunD4YvSbcdTh2rNLizpL698WPtPKXJi",
      mintAddress: "8X68hCZdtApyzraEdQ3oRjZ3K5rzzwSoeERa4Cu2PZ8Y",
    },
    {
      tokenName: "THE SUM OF US #7",
      auction: "HTv5Hv9LeMjfHDqMdSPrPH5G8VPmhvb17PGJ2J3mVvqn",
      mintAddress: "7Hh2WLxizmuNsxm2Ni8q8v5zuwbLbWmJKMGkZ63ebezG",
    },
    {
      tokenName: "THE SUM OF US #8",
      auction: "5mkrb1hxxbghjzzWNc4QyijKPQHS7zD8k5Wc1DBMChTR",
      mintAddress: "8pjZKHx7h3T3BpHTppXj8Wh7depTeeN6HTEpHsrY33P3",
    },
  ],
  eden: [
    {
      tokenName: "The Bun Round #1",
      auction: "AKHVEsTWYDxhNhdTBYgop5eqwLZEoChf8ZomUBUPGJMC",
      mintAddress: "7Rnof9SeAdLcNbRa3ypG7WrForF3r69NybtEaGht2R2t",
    },
    {
      tokenName: "The Bun Round #2",
      auction: "BoQqwWFxb3WKGDvM1rKQeE4qrP131wFCKLrmuo9Cq4bJ",
      mintAddress: "5ujyvvS1nTMyMNz7mbKeduA2dxeNk6juUaJoZ3jMkV7V",
    },
    {
      tokenName: "The Bun Round #3",
      auction: "FG1ECtibbrZjA7oWCxs2UcggoQgTQH5zf1jshGSRW4LP",
      mintAddress: "FMYozP4WMUrriugYVGphmyfQuXRF1kidE4houyyDedmz",
    },
    {
      tokenName: "The Bun Round #4",
      auction: "2VftKC15hagyJ2F3scFRTMs5GSoherzjUs5fw7Au9CKk",
      mintAddress: "C6qQmGmaZZb47AXUgmCHzFpt1YttHzC7oHbz6nV3Wn9z",
    },
    {
      tokenName: "The Bun Round #5",
      auction: "96JFHy6kU8k3q45BmRJnyWAhYqxNjypVXFun7ai2AeLd",
      mintAddress: "4wubCqCceqz2byzDzSakNwttZ93WuhpUGshAUmt5mJ4V",
    },
    {
      tokenName: "The Bun Round #6",
      auction: "FUpiPrQzoyCk7WkrftbepvV9s1uXuZofe39x5Nz2G1FJ",
      mintAddress: "ABPreZiwML55xhfgo8Ura6jVYxA3DYMd44Ag7ufuG7mr",
    },
    {
      tokenName: "The Bun Round #7",
      auction: "5YCeEe7ZjYJ2pxKiU6ZqPrLA2FCXxafDJvYB9Kc6fyok",
      mintAddress: "Cgf4TPvSw5UXmEps212ggxZSLnonWDP5ckwv8n6T1Y64",
    },
    {
      tokenName: "The Bun Round #8",
      auction: "7ypzrqyHAHF3C8xhiPvdqKJxTEsVdwsiEk6cdhYtAien",
      mintAddress: "4siCnDReJe54pi6uLiCbBQv4HmHAoKDBcT1FCwvLFdjs",
    },
    {
      tokenName: "The Bun Round #9",
      auction: "3CfUucuF3zYy7QBf7BRqtv31GQmAqy2WzgbRfBGSBFof",
      mintAddress: "EVusg9itCaMCPW9Hu7BPb1MWXoijnzdw1jL7Z3C7waEt",
    },
  ],
  cori: [
    {
      tokenName: "The Snack Console #1",
      auction: "5PDCUN8MbJjk9bDXWbWGP1L66ektEhFuZWFmez9znmvF",
      mintAddress: "5q43E9fxi6ifmHzZAhebUMBiMXfn7xsxPiZdJGubYKpx",
    },
    {
      tokenName: "The Snack Console #2",
      auction: "8tmEvGLvsxPuxt6RtxjpZJbZn7aNz5pWgDSZV3zQaX7Y",
      mintAddress: "6j5Vm2yjsxQELnHpN9erTNQMKELKsTh3jL7uphirJs3P",
    },
    {
      tokenName: "The Snack Console #3",
      auction: "8QdVizHESHfUztjFD1bCDNJ9ZufBBNpi5XWWd5gpm4Yg",
      mintAddress: "5Z2Z6udJoQkwWmkWcN4yF2vd9jA4zvBn3HGcUJtAbHbU",
    },
    {
      tokenName: "The Snack Console #4",
      auction: "AdPrrdG4JFNpNDvHhKdt9hjuViEYCJcXL8Wu4fPFwb94",
      mintAddress: "mtcfxaYVriAUXFfKRbpE4J6ocm3t16x5Xq6JVt2zdZC",
    },
    {
      tokenName: "The Snack Console #5",
      mintAddress: "D9RDevqUxG8Aq7CpF4DYkpRyLfp5uXondcFJE62NTr19",
      auction: "4uYdCkGpJdnUAiUqA3WQfQdGuG7SRyDWWiya2kr4NX9o",
    },
    {
      tokenName: "The Snack Console #6",
      mint: "DVeXkFQUaFtKgXFBYzSk4qgEUDUPSqADegjPAdMHYJZS",
      auction: "Jb4afqaAiZTfUSsQ1cjZTLG3Y5MQxKm97qTgpGYYcWA",
    },
    {
      tokenName: "The Snack Console #7",
      auction: "4U8CssxJ1AkUTefBZgDrSvm9KRH4CYxjSPKZVWUo1Q75",
      mintAddress: "D1X26TuEh9FM98a5xVvTTvjKRg9wmLcBRFMqJYmgzXf3",
    },
    {
      tokenName: "The Snack Console #8",
      auction: "3GzUkeemtWwk3RS4WpGAestyjKtmU9KT7My6Kvx1Cniv",
      mintAddress: "G1QdupYbC4bCR2xNBsFGiiGzKE7k4pDy3BogtHyDadMM",
    },
    {
      tokenName: "The Snack Console #9",
      auction: "HtgXMEbUpAFHXeEGGwPgaHf4vMKmLXoP3THTMy6Z17GB",
      mintAddress: "9cwVWRTMkzH2bpJWBaWaBWAQXAUZQ2XvHkXEmoqncHT6",
    },
  ],
  emily: [
    {
      tokenName: "Together Apart Together #1",
      auction: "H1dJqVzd9TwJxi3wDmM5TKczjRxhvD1xHRBrd7L6cU9D",
      mintAddress: "3LQCsEKpHkGmwHHz8j3Pe6boGX16nLZWPE34FKKDKmQV",
    },
    {
      tokenName: "Together Apart Together #2",
      auction: "2CpYP9xCwySaDLxLsvMQDqiQu1RCYjNmquGDYk52GnSJ",
      mintAddress: "F7zWXz8gXGBxsQ5XQgk3U5YuPR876DerKteUCY2kNoRd",
    },
    {
      tokenName: "Together Apart Together #3",
      auction: "2hMKsWEj4LnB9iLTRw6vHj6sGGpHUut48kgupp5d39Um",
      mintAddress: "HvBfdmeNHXPjL3ubMmMva6kgqDjL6Xy5SFY6rfCd21LZ",
    },
    {
      tokenName: "Together Apart Together #4",
      auction: "EXsmurJtLUBMbCgg6Kvu3rejGyCPTxzXAnr8z96wNYRJ",
      mintAddress: "3Cb5GvdcGBw36GkdN2aLo9URsYwhA1kSW4B89PvHoSbG",
    },
    {
      tokenName: "Together Apart Together #5",
      auction: "8bGeCgYQrJksQU6FoGCcVJEWUTvATsuVSj6u2cwSJgtm",
      mintAddress: "FNm25YmnhADytY3tx3VdMNF5ELpB2mUKqvp9jbPRixux",
    },
    {
      tokenName: "Together Apart Together #6",
      auction: "H9EzY34ZhBRykd86xv7pfUJ8xaCP2CHAA5gCSVWBzN5P",
      mintAddress: "FS5aySjAc7GmM5m1zB6mKkokweoYgh5G7onsSCMKnvx4",
    },
    {
      tokenName: "Together Apart Together #7",
      mintAddress: "9o62v2UYX9VDL6xw5y2fjxyR37avGRqunH2DoDjc6aZh",
      auction: "4wtfhw7EDwc66xSwh5HL3aLUCxK432EXD6aBLdAf91SL",
    },
    {
      tokenName: "Together Apart Together #8",
      auction: "EZMaFKvm8Saw2iNUgFbUi7LzusRXJhLZMFAjAYPKsTGb",
      mintAddress: "DxtTibCXRTFJaHGXQBdCSUyVvaETcXY9Te2K5H3GBHrz",
    },
    {
      tokenName: "Together Apart Together #9",
      auction: "5C9KJ4MLDiUkwz3GovkkMhMfj9iTgUSsjPk8ZjzgMh6j",
      mintAddress: "Aqj2v6nWNafTfbWjCKJQGAe23WKVXUFb32GC6wpyF7tK",
    },
  ],
  gabrielle: [
    {
      tokenName: "Unwrapping Memories #1",
      auction: "6k6eXF1xhdKDM8xEiPyZgw2AEXBWrAGbwCkzoDvNEeW1",
      mintAddress: "H8juupW35HdHF8SBwem7FLufDiaR9JecBuknJrDFAPof",
    },
    {
      tokenName: "Unwrapping Memories #2",
      auction: "23aKccZSUzDGn1471YnggvkN9FDUhg8FrmEwZqXct7Qm",
      mintAddress: "5bhHkTeukjg9vQAGCrN6oqf17EucFyttoCVTqP9LjPf",
    },
    {
      tokenName: "Unwrapping Memories #3",
      auction: "8CyJ7j3srezvMTcX2sXTLZjXPw3c2YtbiXNAjk7t99QM",
      mintAddress: "HAVBtuUEKLREwwXfFfmXjoHuPaXWG8fnTAbmj9zm5Lxv",
    },
    {
      tokenName: "Unwrapping Memories #4",
      auction: "4AaGF6G9KQLQeapRKBzQsCn77vRGztwJ7BWWUQKSpwv1",
      mintAddress: "8RDQ4u965Wx5Hrzsh7YoCeMwQ1R1k8vyVsn3a6QtnG7",
    },
    {
      tokenName: "Unwrapping Memories #5",
      auction: "GsjMr7bMKegutec7iQah6J6UNFJbkZXWG1zTziaQAri3",
      mintAddress: "Cyi1mtiJ4UYjTYoyKCZQjGA1BEoB7BMuS2i1cevi5pNN",
    },
    {
      tokenName: "Unwrapping Memories #6",
      mintAddress: "FPHrsJcXNCpXyHtxcEUEDT3zShfRGFQfDof6eyytfgDM",
      auction: "9Xp7qi1qnnaHUGN7cdat1xn2i7v14oh5ka2RfpgWWwse",
    },
    {
      tokenName: "Unwrapping Memories #7",
      auction: "Awk7eopLe39sjpvbCyHyBmYfRFdPBoFJVjAWbjxzE9Dg",
      mintAddress: "9th1GSNbAZwJ7uAeSepksqfPN9RWSPBX23VdweMcnZFK",
    },

    {
      tokenName: "Unwrapping Memories #8",
      auction: "2wJwEpbGcLVsvfQAWBDvj9Ue4qSLy5WMMhxLgMiq9JWU",
      mintAddress: "B1LwVT6HSvUUbUXuoBUkbGEuxAAALxNEnt1DAh2srgGr",
    },
    {
      tokenName: "Unwrapping Memories #9",
      auction: "F4vAGW6Fezu1BLYgyJxQUg5KWK4kWEdaj4oiW1brR6Cv",
      mintAddress: "9uR2mCRQajGN7e6C5TQ6jsb98GZ99F77MJTthXhhvo3k",
    },
  ],
};

export default tokens;
