import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import {
  Nav,
  IconAndText,
  Sideways,
  NavbarContainer,
  NavLogo,
  NavIcon,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NaviHashLink,
  Break,
  GuideBurger,
} from "./styles";
import { Dropdown } from "../Dropdown";
import { MenuItems } from "../Dropdown/menu.items";

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => {
    setClick(!click);
  };

  const onMouseEnter = () => {
    if (window.innerWidth < 1030) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseEnterDiffNav = () => {
    setDropdown(false);
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 1030) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav>
          <IconAndText>
            <NavIcon />
            <Sideways>welcome.to.chinatown</Sideways>
          </IconAndText>

          <NavbarContainer>
            <NavLogo to="/about" onClick={closeMobileMenu}>
              // LNY NFT <Break /> Collection
            </NavLogo>
            <MobileIcon onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </MobileIcon>
            <NavMenu click={click}>
              <NavItem>
                <NavLinks
                  to="/about"
                  onClick={closeMobileMenu}
                  onMouseEnter={onMouseEnterDiffNav}
                >
                  + about
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="/artists"
                  onClick={closeMobileMenu}
                  onMouseEnter={onMouseEnterDiffNav}
                >
                  + artists
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="/guide"
                  onClick={closeMobileMenu}
                  onMouseEnter={onMouseEnter}
                >
                  + guide
                </NavLinks>
                {dropdown && <Dropdown onMouseLeave={onMouseLeave} />}
                {/* <Dropdown /> */}
              </NavItem>
              <GuideBurger>
                {MenuItems.map((item, index) => {
                  return (
                    <NaviHashLink to={item.path} onClick={closeMobileMenu}>
                      {item.title}
                    </NaviHashLink>
                  );
                })}
              </GuideBurger>

              <NavItem>
                <NavLinks
                  to="/auctions"
                  onClick={closeMobileMenu}
                  onMouseEnter={onMouseEnterDiffNav}
                >
                  + auctions
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="/gift"
                  onClick={closeMobileMenu}
                  onMouseEnter={onMouseEnterDiffNav}
                >
                  + gift
                </NavLinks>
              </NavItem>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
