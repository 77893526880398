import artist1 from "../assets/1_cori.jpeg";
import artist2 from "../assets/2_emily.png";
import artist3 from "../assets/spaced_agency.png";
import artist4 from "../assets/wendyfok.jpeg";
import artist5 from "../assets/4_eden.jpeg";
import artist6 from "../assets/gabrielle.jpeg";
import tray1 from "../assets/Artworks/CoriHuang/Overall/0.png";
import tray2 from "../assets/Artworks/EmilyYang/Overall/0.png";
import tray3 from "../assets/Artworks/SpacedAgency/Overall/0.gif";
import tray4 from "../assets/Artworks/WendyFok/Overall/0.jpg";
import tray5 from "../assets/Artworks/EdenLew/Overall/0.png";
import tray6 from "../assets/Artworks/GentleOriental/Overall/0.png";

import tray1_1 from "../assets/Artworks/CoriHuang/300/1.png";
import tray1_2 from "../assets/Artworks/CoriHuang/300/2.png";
import tray1_3 from "../assets/Artworks/CoriHuang/300/3.png";
import tray1_4 from "../assets/Artworks/CoriHuang/300/4.png";
import tray1_5 from "../assets/Artworks/CoriHuang/300/5.png";
import tray1_6 from "../assets/Artworks/CoriHuang/300/6.png";
import tray1_7 from "../assets/Artworks/CoriHuang/300/7.png";
import tray1_8 from "../assets/Artworks/CoriHuang/300/8.png";
import tray1_9 from "../assets/Artworks/CoriHuang/300/9.png";

import tray2_1 from "../assets/Artworks/EmilyYang/300/0.png";
import tray2_2 from "../assets/Artworks/EmilyYang/300/1.png";
import tray2_3 from "../assets/Artworks/EmilyYang/300/2.png";
import tray2_4 from "../assets/Artworks/EmilyYang/300/3.png";
import tray2_5 from "../assets/Artworks/EmilyYang/300/4.png";
import tray2_6 from "../assets/Artworks/EmilyYang/300/5.png";
import tray2_7 from "../assets/Artworks/EmilyYang/300/6.png";
import tray2_8 from "../assets/Artworks/EmilyYang/300/7.png";
import tray2_9 from "../assets/Artworks/EmilyYang/300/8.png";

import tray3_1 from "../assets/Artworks/SpacedAgency/300/1.gif";
import tray3_2 from "../assets/Artworks/SpacedAgency/300/2.gif";
import tray3_3 from "../assets/Artworks/SpacedAgency/300/3.gif";
import tray3_4 from "../assets/Artworks/SpacedAgency/300/4.gif";
import tray3_5 from "../assets/Artworks/SpacedAgency/300/0.gif";
import tray3_6 from "../assets/Artworks/SpacedAgency/300/5.gif";
import tray3_7 from "../assets/Artworks/SpacedAgency/300/6.gif";
import tray3_8 from "../assets/Artworks/SpacedAgency/300/7.gif";
import tray3_9 from "../assets/Artworks/SpacedAgency/300/8.gif";

import tray4_1 from "../assets/Artworks/WendyFok/300/1.png";
import tray4_2 from "../assets/Artworks/WendyFok/300/2.png";
import tray4_3 from "../assets/Artworks/WendyFok/300/3.png";
import tray4_4 from "../assets/Artworks/WendyFok/300/4.png";
import tray4_5 from "../assets/Artworks/WendyFok/300/5.png";
import tray4_6 from "../assets/Artworks/WendyFok/300/6.png";
import tray4_7 from "../assets/Artworks/WendyFok/300/7.png";
import tray4_8 from "../assets/Artworks/WendyFok/300/8.png";
import tray4_9 from "../assets/Artworks/WendyFok/300/9.png";

import tray5_1 from "../assets/Artworks/EdenLew/300/1.png";
import tray5_2 from "../assets/Artworks/EdenLew/300/2.png";
import tray5_3 from "../assets/Artworks/EdenLew/300/3.png";
import tray5_4 from "../assets/Artworks/EdenLew/300/4.png";
import tray5_5 from "../assets/Artworks/EdenLew/300/5.png";
import tray5_6 from "../assets/Artworks/EdenLew/300/6.png";
import tray5_7 from "../assets/Artworks/EdenLew/300/7.png";
import tray5_8 from "../assets/Artworks/EdenLew/300/8.png";
import tray5_9 from "../assets/Artworks/EdenLew/300/9.png";

import tray6_1 from "../assets/Artworks/GentleOriental/300/1.png";
import tray6_2 from "../assets/Artworks/GentleOriental/300/2.png";
import tray6_3 from "../assets/Artworks/GentleOriental/300/3.png";
import tray6_4 from "../assets/Artworks/GentleOriental/300/4.png";
import tray6_5 from "../assets/Artworks/GentleOriental/300/5.png";
import tray6_6 from "../assets/Artworks/GentleOriental/300/6.png";
import tray6_7 from "../assets/Artworks/GentleOriental/300/7.png";
import tray6_8 from "../assets/Artworks/GentleOriental/300/8.png";
import tray6_9 from "../assets/Artworks/GentleOriental/300/9.png";

import artist_tokens from "./artists.tokens.index";

const createAuctionLink = (auction) =>
  `https://nft.welcometochinatown.com/live/#/auction/${auction}`;

export const artistsArray = [
  {
    img: artist1,
    trayimg: tray1,
    alt: "Cori-Huang-image",
    name: "Cori Huang",
    iglink: "https://www.instagram.com/corcorarium",
    websitelink: "https://corihuang.com/",
    twitter: "https://twitter.com/corcorarium",
    bio: "Cori Huang (she/her) was born and raised in New Jersey with a love of good stories and fictional worlds. Previously, she has worked with various tech startups wearing different design hats, mostly web design and illustration. You can often find her reading, dabbling in various crafts, and trying to perfect her bubble tea order.",
    trayname: "The Snack Console",
    traydesc:
      "Growing up, I didn't always know how to connect with my family, but our gatherings always had snacks and games to draw us together. I wanted to capture the warm bustle of grandparents, parents, aunts, uncles, and kids cracking watermelon seeds and crunching shrimp crackers against a backdrop of endless rounds of cards, mahjong, and Smash.",
    individuals: [
      {
        image: tray1_1,
        nftlink: createAuctionLink(artist_tokens.cori[0].auction),
      },
      {
        image: tray1_2,
        nftlink: createAuctionLink(artist_tokens.cori[1].auction),
      },

      {
        image: tray1_3,
        nftlink: createAuctionLink(artist_tokens.cori[2].auction),
      },
      {
        image: tray1_4,
        nftlink: createAuctionLink(artist_tokens.cori[3].auction),
      },
      {
        image: tray1_5,
        nftlink: createAuctionLink(artist_tokens.cori[4].auction),
      },
      {
        image: tray1_6,
        nftlink: createAuctionLink(artist_tokens.cori[5].auction),
      },
      {
        image: tray1_7,
        nftlink: createAuctionLink(artist_tokens.cori[6].auction),
      },
      {
        image: tray1_8,
        nftlink: createAuctionLink(artist_tokens.cori[7].auction),
      },
      {
        image: tray1_9,
        nftlink: createAuctionLink(artist_tokens.cori[8].auction),
      },
    ],
  },
  {
    img: artist2,
    trayimg: tray2,
    alt: "Emily-Yang-image",
    alt_tray: "tray of togethernes by Emily Yang",
    name: "Emily B. Yang",
    iglink: "https://www.instagram.com/studioemilybyang",
    websitelink: "http://www.emilybyang.com/",
    twitter: "https://twitter.com/ebyangux",
    bio: "Emily B. Yang (she/her)  is an artist + designer + educator + social dreamer. She employs printmaking as a medium to reconcile the past with the present, and uses stories of the future to create provocations for today.",
    trayname: `Together Apart Together: \nA Tray of the Asian Diaspora`,
    traydesc:
      "What components come together to form a community? This Tray of Togetherness plays on the shape of the traditional Lunar New Year sweets platter; but instead of snacks, this tray presents and celebrates a disasporic community finding ways to come together in ways that feel both traditional and new.",
    individuals: [
      {
        image: tray2_1,
        nftlink: createAuctionLink(artist_tokens.emily[0].auction),
      },
      {
        image: tray2_2,
        nftlink: createAuctionLink(artist_tokens.emily[1].auction),
      },

      {
        image: tray2_3,
        nftlink: createAuctionLink(artist_tokens.emily[2].auction),
      },
      {
        image: tray2_4,
        nftlink: createAuctionLink(artist_tokens.emily[3].auction),
      },
      {
        image: tray2_5,
        nftlink: createAuctionLink(artist_tokens.emily[4].auction),
      },
      {
        image: tray2_6,
        nftlink: createAuctionLink(artist_tokens.emily[5].auction),
      },
      {
        image: tray2_7,
        nftlink: createAuctionLink(artist_tokens.emily[6].auction),
      },
      {
        image: tray2_8,
        nftlink: createAuctionLink(artist_tokens.emily[7].auction),
      },
      {
        image: tray2_9,
        nftlink: createAuctionLink(artist_tokens.emily[8].auction),
      },
    ],
  },
  {
    img: artist3,
    trayimg: tray3,
    alt: "spaced-agency-img",
    alt_tray: "tray of togethernes by spaced agency",
    name: "SPACED AGENCY",
    twitter: "https://twitter.com/tkjustinng",
    iglink: "https://www.instagram.com/spaced.agency",
    websitelink: "http://spaced-agency.com/",
    bio: "SPACED AGENCY is a design and research studio working in the in-between. Founder T.K. Justin Ng is an architectural designer and artist. Rhea Jiang is a writer and researcher. Their current projects investigate how histories of trans-Pacific sociocultural movement and exchange manifest in the North American built environment.",
    trayname: "THE SUM OF US",
    traydesc:
      "If dim sum restaurants are stages for Chinatown to come together, dim sum servers are their forgotten orchestra. THE SUM OF US elevates these familiar figures into interactive digital sculptures that engage with the uniquely social medium of an NFT.",
    individuals: [
      {
        image: tray3_1,
        nftlink: createAuctionLink(artist_tokens.spaced[0].auction),
      },
      {
        image: tray3_2,
        nftlink: createAuctionLink(artist_tokens.spaced[1].auction),
      },

      {
        image: tray3_3,
        nftlink: createAuctionLink(artist_tokens.spaced[2].auction),
      },
      {
        image: tray3_4,
        nftlink: createAuctionLink(artist_tokens.spaced[3].auction),
      },
      {
        image: tray3_5,
        nftlink: null,
      },
      {
        image: tray3_6,
        nftlink: createAuctionLink(artist_tokens.spaced[4].auction),
      },
      {
        image: tray3_7,
        nftlink: createAuctionLink(artist_tokens.spaced[5].auction),
      },
      {
        image: tray3_8,
        nftlink: createAuctionLink(artist_tokens.spaced[6].auction),
      },
      {
        image: tray3_9,
        nftlink: createAuctionLink(artist_tokens.spaced[7].auction),
      },
    ],
  },
  {
    img: artist4,
    trayimg: tray4,
    alt: "Wendy-Fok-img",
    alt_tray: "tray of togethernes by Wendy Fok",
    name: "Wendy W Fok | 霍渭瑜",
    twitter: "https://twitter.com/W_W_F",
    iglink: "https://www.instagram.com/WendyWFok",
    websitelink: "http://wendywfok.com",
    bio: "Wendy W Fok (she/them), trained as an architect, is interested in design, technology, and creative solutions for the built environment. They have experience in Product Development and Program Management from Zero to Launch, Design-Build, Manufacturing, Hardware/Software, and Digital Fabrication. Fok holds a Doctor of Design from Harvard Graduate School of Design (GSD), obtained her Master of Architecture and Certification of Urban Policy/Planning from Princeton University, and a Bachelor of Arts in Architecture with a Concentration in Economics (Statistics) from Barnard College, Columbia University.",
    trayname: "Lunar Everyday an Object",
    traydesc:
      "In collaboration with Cölle LA, this special series of Lunar Everyday an Object celebrates the dualistic approach of digital and physical property, with Non Fungible Tokens (NFT) along with a collectable and limited edition candle that is designed as a pair with the auction. Auction winners receive both the digital object and physical candle. Everyday an Object” is a series of design-objects from a collection of moments drawn from the past decade of our lead designer, Wendy W Fok. The series engages further on “everyday objects” that is inspired by the Michel de Certeau “The Practice of Everyday Life”— a book that Fok continually read throughout their undergraduate and graduate architectural studies. ",
    individuals: [
      {
        image: tray4_1,
        nftlink: createAuctionLink(artist_tokens.wendy[0].auction),
      },
      {
        image: tray4_2,
        nftlink: createAuctionLink(artist_tokens.wendy[1].auction),
      },

      {
        image: tray4_3,
        nftlink: createAuctionLink(artist_tokens.wendy[2].auction),
      },
      {
        image: tray4_4,
        nftlink: createAuctionLink(artist_tokens.wendy[3].auction),
      },
      {
        image: tray4_5,
        nftlink: createAuctionLink(artist_tokens.wendy[4].auction),
      },
      {
        image: tray4_6,
        nftlink: createAuctionLink(artist_tokens.wendy[5].auction),
      },
      {
        image: tray4_7,
        nftlink: createAuctionLink(artist_tokens.wendy[6].auction),
      },
      {
        image: tray4_8,
        nftlink: createAuctionLink(artist_tokens.wendy[7].auction),
      },
      {
        image: tray4_9,
        nftlink: createAuctionLink(artist_tokens.wendy[8].auction),
      },
    ],
  },
  {
    img: artist5,
    trayimg: tray5,
    alt: "eden-lew-image",
    alt_tray: "tray of togethernes by Eden Lew",
    name: "Eden Lew | 劉依文",
    twitter: "https://twitter.com/edendenden",
    iglink: "https://www.instagram.com/edenwooperlewper",
    websitelink: "https://www.doubletake.design",
    bio: "Eden Lew (she/her) is an interdisciplinary designer from Houston, Texas. She is always looking for excuses to draw (and eat) food and be part of fun experiments. Eden is currently a co-founder of Double Take Labs, a creative technology and experience design studio in NYC.",
    trayname: "The Bun Round",
    traydesc:
      "Once at dim sum with friends, after eating several rounds of food already, we decided it was a great idea to end our meal with a round of steamed and baked buns from every cart that passed by. It was a delicious, gastronomical mistake, but we were all filled with laughter, jokes, friendship and, quite literally, bread in that moment. I wish everyone the experience of great times over a dim sum spread.",
    individuals: [
      {
        image: tray5_1,
        nftlink: createAuctionLink(artist_tokens.eden[0].auction),
      },
      {
        image: tray5_2,
        nftlink: createAuctionLink(artist_tokens.eden[1].auction),
      },

      {
        image: tray5_3,
        nftlink: createAuctionLink(artist_tokens.eden[2].auction),
      },
      {
        image: tray5_4,
        nftlink: createAuctionLink(artist_tokens.eden[3].auction),
      },
      {
        image: tray5_5,
        nftlink: createAuctionLink(artist_tokens.eden[4].auction),
      },
      {
        image: tray5_6,
        nftlink: createAuctionLink(artist_tokens.eden[5].auction),
      },
      {
        image: tray5_7,
        nftlink: createAuctionLink(artist_tokens.eden[6].auction),
      },
      {
        image: tray5_8,
        nftlink: createAuctionLink(artist_tokens.eden[7].auction),
      },
      {
        image: tray5_9,
        nftlink: createAuctionLink(artist_tokens.eden[8].auction),
      },
    ],
  },
  {
    img: artist6,
    trayimg: tray6,
    alt: "artist-image",
    alt_tray: "tray of togethernes by Gentle Oriental",
    name: "Gabrielle Widjaja",
    twitter: "",
    iglink: "https://www.instagram.com/gentle.oriental/",
    websitelink: "https://www.gentleoriental.co/",
    bio: "Gabrielle Widjaja (she/her) is a Chinese/Indonesian-born, California-raised, Brooklyn-based creative. Her practice revolves around making sense of diaspora Asian American culture through manifestations of visual ephemera (illustration, design, objects, tattoos, etc.) ",
    trayname: "Unwrapping Memories",
    traydesc:
      "I grew up extremely estranged from my Chinese heritage; I never celebrated Lunar New Year until I was a college student and I was starting to learn more about my roots. Because of this, I have no traditions; I've had to learn what people do each year, but that's okay. My friends and I create our own traditions, and I think that's the beauty of growing up as part of the Asian diaspora, being able to evolve our own cultures. Togetherness Trays are usually filled with traditional dried fruits and treats, but since I never had those growing up, I filled this tray with classic Asian snacks which hold the sweetest memories of mine.",
    individuals: [
      {
        image: tray6_1,
        nftlink: createAuctionLink(artist_tokens.gabrielle[0].auction),
      },
      {
        image: tray6_2,
        nftlink: createAuctionLink(artist_tokens.gabrielle[1].auction),
      },

      {
        image: tray6_3,
        nftlink: createAuctionLink(artist_tokens.gabrielle[2].auction),
      },
      {
        image: tray6_4,
        nftlink: createAuctionLink(artist_tokens.gabrielle[3].auction),
      },
      {
        image: tray6_5,
        nftlink: createAuctionLink(artist_tokens.gabrielle[4].auction),
      },
      {
        image: tray6_6,
        nftlink: createAuctionLink(artist_tokens.gabrielle[5].auction),
      },
      {
        image: tray6_7,
        nftlink: createAuctionLink(artist_tokens.gabrielle[6].auction),
      },
      {
        image: tray6_8,
        nftlink: createAuctionLink(artist_tokens.gabrielle[7].auction),
      },
      {
        image: tray6_9,
        nftlink: createAuctionLink(artist_tokens.gabrielle[8].auction),
      },
    ],
  },
];
