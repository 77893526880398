import { Container, ImageContainer, TextContainer } from "../styles";
import {
  ArtistInfo,
  ArtistName,
  ArtistBio,
  ArtistsLayout,
  ArtistImg,
  IconsContainer,
  SocialIconLink,
  ArtistButton,
} from "./artists.styles";
import { FaInstagram, FaTwitter } from "react-icons/fa";
import { ReactComponent as IgIcon } from "../assets/ig_logo_thin.svg";
import { ReactComponent as ExternalIcon } from "../assets/external_link_icon.svg";
import { AiOutlineLink } from "react-icons/ai";
import { artistsArray } from "./artists.data.index";

const ArtistsPage = () => (
  <>
    <Container>
      <h1>Artists</h1>
      {artistsArray.map((artist, index) => {
        return (
          <ArtistsLayout>
            <ArtistImg src={artist.img} alt={artist.alt} />

            <ArtistInfo id={artist.name}>
              <ArtistName>{artist.name} </ArtistName>
              <IconsContainer>
                {artist.iglink && (
                  <SocialIconLink
                    path="/external"
                    href={artist.iglink}
                    target="_blank"
                    rel="noopener"
                    aria-label="Instagram"
                  >
                    <FaInstagram />
                  </SocialIconLink>
                )}
                {artist.twitter && (
                  <SocialIconLink
                    path="/external"
                    href={artist.twitter}
                    target="_blank"
                    rel="noopener"
                    aria-label="Twitter"
                  >
                    <FaTwitter />
                  </SocialIconLink>
                )}
                {artist.websitelink && (
                  <SocialIconLink
                    path="/external"
                    href={artist.websitelink}
                    target="_blank"
                    aria-label="Artist-Link"
                  >
                    <AiOutlineLink />
                  </SocialIconLink>
                )}
              </IconsContainer>
              <ArtistBio>{artist.bio}</ArtistBio>

              <ArtistButton
                to={`/auctions#${artist.trayname}`}
                bgColor="#000"
                outline="#FFFF00"
                maxWidth="45%"
              >
                <b>View their tray!</b>
              </ArtistButton>
            </ArtistInfo>
          </ArtistsLayout>
        );
      })}
    </Container>
  </>
);

export default ArtistsPage;
