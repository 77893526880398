import styled, { createGlobalStyle } from "styled-components";
import { AiOutlineClose } from "react-icons/ai";

export const GlobalStyle = createGlobalStyle`

@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');
  * {
  box-sizing: border-box;
  
  margin:0;
  padding:0;
  
 } 

 h1, h2, h3, body, button {
  font-family: 'Source Code Pro', monospace;
  color: white;
  background-color:black;
  
 }
 mark{
   background-color:white;
 }

 a{
  font-family: 'Source Code Pro', monospace;
  color: white;

 }
 h1{
   font-size:3rem;
   text-decoration: underline;
   justify-items: center;
   justify-content: center;
   text-align:center;
   margin-bottom:4rem;
   @media screen and (max-width: 900px) {
     font-size:2.5rem;
   }
  }

 h2{
  font-size:2.5rem;
  margin-bottom: 2rem;
  @media screen and (max-width: 900px) {
     font-size:2rem;
   }
 } 
 h3{
   font-size: 1.3rem;
   display:inline;

 }
 body, a{
   font-weight:300;
   font-size:1.1rem;
   text-underline-offset: 0.2rem;
   line-height: 1.4;
 }
 h4{
   font-size:4rem;
   @media screen and (max-width: 900px) {
     font-size:3rem;
   }
 }

 u, a, b{
   display:inline;
 }

 a, a:visited{
   text-decoration:none;
 }

 a {
  &:hover {
    color: #FFE000;
  }
 }



`;

export const Container = styled.div`
  background-color: black;
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-top: 5rem;
  margin-right: auto;
  margin-left: auto;
  white-space: pre-wrap;
  @media screen and (max-width: 1030px) {
    margin: auto;
    max-width: 40rem;
    margin-top: 5rem;
    /* background: purple; */
  }
`;

export const SocialIconLink = styled.div`
  color: fff;
  font-size: 1rem;
`;

export const A = styled.a`
  text-decoration: underline;
`;

export const FullScreen = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000050;
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out;
  visibility: ${({ click }) => (click ? "visible" : "hidden")};
  opacity: ${({ click }) => (click ? "1" : "0")};

  overflow: hidden;
  z-index: 999;
`;

export const FullScreenImage = styled.img`
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
  transform: ${({ click }) => (click ? "scale(1)" : "scale(0)")};
`;
export const CloseIcon = styled(AiOutlineClose)`
  position: fixed;
  top: 10px;
  right: 10px;
  width: 2rem;
  height: 2rem;
  padding: 5px;
  stroke: #fff;
  cursor: pointer;
`;

export const Spacer = styled.div`
  margin-top: ${(props) => props.size}rem;
`;

export const BlinkMe = styled.h2`
margin-top:1rem;
  animation: blinker 2.5s linear infinite;
  @keyframes blinker {
  50% {
    opacity: 0;
  }
`;
