import styled from "styled-components";
import { Container } from "../styles";

import { NavHashLink } from "react-router-hash-link";

var max_width = "78";
export const Instructions = styled.div`
  font-size: 0.9rem;
`;
export const AuctionContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const EasterEggGuide = styled.div`
  background-color: white;
  color: black;
  border-radius: 25px;
  padding: 2rem;
  max-width: ${max_width}%;

  @media screen and (max-width: 1030px) {
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    width: 100%;
    /* padding: 1.5rem; */
  }
  @media screen and (max-width: 800px) {
    padding: 1.5rem;
  }
`;

export const EEHeader = styled.h2`
  background-color: white;
  color: black;
  display: inline-block;
  padding: 0;
`;

export const EEWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  width: 100%;
  max-width: 50rem;
  justify-content: space-around;
  margin-top: 2rem;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
`;
export const EasterEggImage = styled.img`
  width: 40%;
  object-fit: contain;
  @media screen and (max-width: 1100px) {
    width: 70%;
  }
  @media screen and (max-width: 550px) {
    width: 100%;
  }
`;

export const AuctionLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 95%;
  outline-color: yellow;
  margin: auto;
  margin-bottom: 12rem;
  margin-top: 8rem;

  @media screen and (max-width: 1030px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    max-width: 100%;
    margin-bottom: 8rem;
  }
`;

//FLEX LEFT
export const TrayGrid = styled.div`
  min-width: 495px;
  min-height: 495px;
  max-width: 495px;
  max-height: 495px;

  height: 100%;
  width: 100%;
  line-height: 0rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  outline: 0.5rem solid white;
  margin: 0px;
  padding: 0px;
  @media screen and (max-width: 500px) {
    min-width: 300px;
    min-height: 300px;
    max-width: 300px;
    max-height: 300px;
  }
  /* box-shadow: 8px 8px #fdc500; */
`;
export const NFTMask = styled.a``;
export const NFTNum = styled.div`
  background-color: white;
  color: black;
  position: absolute;
  border-bottom-right-radius: 6px;
  font-weight: bold;
  @media screen and (max-width: 500px) {
    font-size: 0.8rem;
  }
`;

export const NFTPrice = styled.div`
  background-color: white;
  color: black;
  position: absolute;
  text-align: right;
  right: 0px;
  bottom: 0px;
  z-index: 30;
  font-weight: bold;
  border-top-left-radius: 6px;
  @media screen and (max-width: 500px) {
    font-size: 0.8rem;
  }
`;
export const NFTImg = styled.img`
  flex: 0 0 32%; /* don't grow, don't shrink, width */
  height: 165px;
  margin: 0rem;
  padding: 0rem;
  outline: 0.2rem solid white;
  @media screen and (max-width: 500px) {
    height: 100px;
    flex: 0 0 32%;
  }
`;
export const NFTATag = styled.a`
  flex: 0 0 32%; /* don't grow, don't shrink, width */
  height: 165px;
  margin: 0rem;
  padding: 0rem;
  position: relative;
  @media screen and (max-width: 500px) {
    height: 100px;
    flex: 0 0 32%;
  }
`;

//FLEX RIGHT
export const TrayInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: 4rem;
  width: 100%;

  @media screen and (max-width: 1030px) {
    width: 90%;
    text-align: center;
    margin-left: 0;
    padding: 4%;
    outline: white 0.5px solid;
    width: 90%;
    margin-left: 0;
    margin-top: 4rem;
  }
`;

export const WhiteOutlineBox = styled.div`
  /* text-align: left;

  @media screen and (max-width: 1030px) {
    padding: 4%;
    outline: white 0.5px solid;
    width: 90%;
    margin-left: 0;
  } */
`;
export const EnlargeTray = styled.div`
  align-self: flex-end;
  margin-bottom: 3rem;

  @media screen and (max-width: 1030px) {
    align-self: center;
  }
`;

export const TextOverlay = styled.div`
  position: relative;
  text-align: center;
  width: 100px;
  height: 100px;
  outline: white solid 2px;

  /* clip-path: circle(75px at center); */
  cursor: pointer;

  opacity: 0.4;
  filter: alpha(opacity=50);
  &:hover {
    opacity: 1;
    filter: alpha(opacity=100);
    transition: all 0.3s ease-in-out;
  }
`;
export const ClickHereText = styled.div`
  position: absolute;
  top: 100%;
  width: 100%;
  /* transform: translate(-50%, -100%); */
  font-size: 0.8rem;
  color: #fff;
  font-weight: bold;
  margin-top: 0.5rem;
`;

export const TrayImg = styled.img`
  height: 100px;
  width: 100px;

  @media screen and (max-width: 1030px) {
  }
`;

export const TrayName = styled.div`
  font-weight: bold;
  margin-bottom: 0.8rem;
  font-size: 1.5rem;
  @media screen and (max-width: 1030px) {
    margin-top: 3rem;
  }
`;

export const TrayDesigner = styled(NavHashLink)`
  cursor: pointer;
  text-decoration: underline;
`;
export const TrayDescription = styled.div`
  margin-top: 3rem;
  margin-bottom: 1rem;
  text-align: left;
  font-size: 1rem;
`;
