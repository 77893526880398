import React from "react";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import Wtclogo from "../../assets/wtc_logo_solid.png";

import {
  FooterContainer,
  Credits,
  CyKimTag,
  FooterSubText,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  WtCLogo,
  SocialIcons,
  SocialIconLink,
} from "./styles";

const Footer = () => {
  return (
    <FooterContainer>
      <SocialLogo
        path="/external"
        href="https://www.welcometochinatown.com/"
        target="_blank"
        rel="noopener"
        aria-label="Instagram"
      >
        <WtCLogo src={Wtclogo} alt="wtc-logo" />
      </SocialLogo>

      <SocialMedia>
        <SocialIcons>
          <SocialIconLink
            path="/external"
            href="https://www.instagram.com/welcome.to.chinatown/"
            target="_blank"
            rel="noopener"
            aria-label="Instagram"
          >
            <FaInstagram />
          </SocialIconLink>

          <SocialIconLink
            href="https://www.facebook.com/welcometochinatown.nyc/"
            target="_blank"
            aria-label="Facebook"
          >
            <FaFacebook />
          </SocialIconLink>
          <SocialIconLink
            href="https://twitter.com/wtchinatown/"
            target="_blank"
            aria-label="Facebook"
          >
            <FaTwitter />
          </SocialIconLink>
        </SocialIcons>
      </SocialMedia>
      <FooterSubText>
        Welcome to Chinatown is a project of Players Philanthropy Fund, a
        Maryland charitable trust recognized by the IRS as a tax-exempt public
        charity under Section 501(c)(3) of the Internal Revenue Code (Federal
        Tax ID: 27-6601178). Contributions to Welcome to Chinatown are
        tax-deductible to the fullest extent of the law.{" "}
      </FooterSubText>

      <WebsiteRights>© Welcome to Chinatown 2022</WebsiteRights>

      <Credits>
        Website designed and developed by{" "}
        <CyKimTag href="https://www.cy-kim.com" target="_blank">
          Cy Kim
        </CyKimTag>
        .
      </Credits>
    </FooterContainer>
  );
};

export default Footer;
