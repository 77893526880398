import styled from "styled-components";
import { A } from "../../styles";

export const FooterContainer = styled.div`
  background-color: #09009b;
  padding: 4rem 0 0.8rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 28rem;
  margin-top: 15rem;
`;
export const Credits = styled.div`
  margin-top: 2rem;
  background-color: #09009b;
  font-size: 0.9rem;
  align-self: flex-start;
  padding-left: 1.2rem;
  color: #999;
`;

export const CyKimTag = styled(A)`
  font-size: 0.9rem;
  color: #999;
`;

export const FooterSubText = styled.p`
  background-color: #09009b;
  margin-bottom: 24px;
  font-size: 0.9rem;
  max-width: 50rem;
  text-align: center;
  @media screen and (max-width: 1000px) {
    max-width: 90%;
  }
`;

export const SocialMedia = styled.div`
  background-color: #09009b;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
`;

export const SocialLogo = styled.a`
  background-color: #09009b;
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
`;

export const WtCLogo = styled.img`
  background-color: #09009b;
  max-width: 5rem;
`;

export const WebsiteRights = styled.small`
  background-color: #09009b;
  color: #fff;
  margin-top: 2rem;
  font-size: 0.9rem;
`;

export const SocialIcons = styled.div`
  background-color: #09009b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
  color: #fff;
  cursor: pointer;
  margin-top: 0.5 rem;
  margin-bottom: 0.5rem;
`;

export const SocialIconLink = styled.a`
  background-color: #09009b;
  color: #fff;
  font-size: 24px;
`;
