import React from "react";
import { OutlineBox } from "../pages/about.styles";
import { AuctionContainer } from "./auction.styles";
import { BlinkMe, Spacer } from "../styles";
import styled from "styled-components";

const ParticipationToken = styled.img`
  max-width: 400px;
  @media screen and (max-width: 450px) {
    width: 80%;
  }
`;

const GiftPage = () => {
  return (
    <AuctionContainer>
      <h1>A Gift for You!</h1>
      <OutlineBox>
        <BlinkMe>Happy Lunar New Year!</BlinkMe>
        Welcome to the Welcome to Chinatown community! This NFT, designed by
        Haruko Harakawa, commemorates this very special Lunar New Year, and
        we're glad you could be a part of it. Owners of this token will join our
        WtC community by unlocking early access to upcoming events, invites to
        impact study listening sessions, and participation in surveys to shape
        our initiatives; we will need your email to make sure we can contact
        you!{"\n\n"}
        <ParticipationToken
          src="https://nft.welcometochinatown.com/static/media/participation_token.a6af842506c1318fdb2a.gif"
          alt="Haruko Harakawa-designed token featuring Welcome to Chinatown"
        />
        <Spacer />
        <p style={{ marginTop: "16px" }}>
          The redemption period for this gift has ended. Thank you to everyone
          who participated!
        </p>
        <Spacer />
      </OutlineBox>

      <Spacer />
    </AuctionContainer>
  );
};

export default GiftPage;
