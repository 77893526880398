import styled from "styled-components";
import { A, Container } from "../styles";

var max_width = "58";
export const AHere = styled(A)`
  color: black;
  font-weight: bold;
`;

export const HeadingContainer = styled(Container)`
  max-width: ${max_width}rem;
  margin-bottom: 6rem;
  margin-top: 0;
`;

export const GuideContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* outline: 1px solid yellow; */
  @media screen and (max-width: 1030px) {
    width: 85%;
    min-width: 300px;
  }
`;

export const CenteredItems = styled.div`
  /* outline: 1px solid red; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-around;
  margin-top: 2rem;
  margin-bottom: 5rem;
  max-width: ${max_width}rem;
  min-height: ${(props) => props.minheight}rem;

  text-align: left;

  @media screen and (max-width: 1030px) {
    width: 85%;
    min-width: 300px;
  }
`;
export const Instructions = styled.ol`
  padding-left: 5rem;
  margin-bottom: 8rem;
  @media screen and (max-width: 1030px) {
    padding-left: 10%;
  }
`;
export const InstructionItem = styled.li`
  margin-top: 3rem;
`;
export const GuideImg = styled.img`
  display: block;
  margin: auto;
  max-width: 27rem;
  margin-top: 3rem;
  margin-bottom: 8rem;
  @media screen and (max-width: 1030px) {
    width: 100%;
    min-width: 10rem;
  }
`;

export const Break = styled.br`
  margin-top: 3rem;
`;
